import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
  createContactInformation,
  createContactFormInformation,
  createContactData,
  createContactDataLayer,
} from '../action';
import {submitContactForm} from '../../../services/DrupalApi';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';
import AsyncCreatable from 'react-select/async-creatable';
import SimpleReactValidator from 'simple-react-validator';
import {
  getCitiesForZipCode,
  getZipCodeByCity,
  getPostalCodeApi,
} from '../../../services/ExternalApi';
import TagManager from 'react-gtm-module';
import {
  COMPANY_TPBAT, COMPANY_TPHOME,
  COUNTRIES,
  ENTITY_TPBAT,
  ENTITY_TPHOME,
  PHONE_INFO_BY_COUNTRY_CODE,
} from '../../../helpers/constants';
import {withTranslation} from 'react-i18next';

/**
 * Main Component
 */
class BasicContactForm extends React.Component {
  /**
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      options: [
        {
          value: '',
          label: props.t('Choose'),
        },
      ],
      termsCheckbox: false,
      showModal: false,
      contactId: null,
      hourOptions: [],
      minuteOptions: [],
      contactphase: 'openForm',
      // contactphase: 'showResult',
      disabledHour: true,
      disabledMinute: true,
      submitResult: {
        image: '',
        message: '',
        messageSecond: '',
      },
      defaultTel: '',
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      city: '',
      cityId: '',
      postalcode: '',
      postalcodeId: '',
      message: '',
      pdf: undefined,
      cityValue: {
        value: '',
        label: '',
      },
      timeout: '',
      cptCode: '',
      project: {},
      arrTrackedEntity: [ENTITY_TPBAT, ENTITY_TPHOME],
      goal: null,
      mailbox: '',
      phoneInfo: PHONE_INFO_BY_COUNTRY_CODE.hasOwnProperty(props.templateConfig.country_code) ?
        PHONE_INFO_BY_COUNTRY_CODE[props.templateConfig.country_code]: PHONE_INFO_BY_COUNTRY_CODE['BE'],
      scriptInit: false,
    };

    this.setValidation();
  }

  /**
   * @param {Object} props
   * @param {Object} state
   * @return {Object} state
   */
  static getDerivedStateFromProps(props, state) {
    state.cptCode = props.propertyModal.property.cptCode;
    state.project = props.project;

    return state;
  }

  /**
   * initialize validation for contactform
   */
  setValidation() {
    const self = this;
    const companyName = this.props.project.companyName;
    this.validator = new SimpleReactValidator({
      validators: {
        intlTelNumber: {
          message: this.props.t('Please add your national prefix. eg: {{phoneFormat}}', {phoneFormat: this.state.phoneInfo.format}),
          rule: (val, params, validator) => {
            const numbers = val.split(/\d/).length - 1;
            return (
              8 <= numbers && numbers <= 20 &&
                validator.helpers.testRegex(val,
                    /^(\+){0,1}(\d|\s|\(|\)){8,20}$/)
            );
          },
        },
        gdpr: {
          message: this.props.t('Please accept our privacy policy.'),
          rule: (val) => {
            return (val === true);
          },
        },
        datenotnull: {
          message: this.props.t('Please enter the appointment date.'),
          rule: (val) => {
            return (val !== '' && val !== null);
          },
          required: true,
        },
        letterWithAccents: {
          message: this.props.t('Your first name may only contain letters.'),
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[A-Za-zÀ-ÖØ-öø-ÿ\s-.]+$/) && params.indexOf(val) === -1;
          },
          required: true,
        },
        zipNoResult: {
          message: this.props.t('The postal code entered is invalid.'),
          rule: (val, params, validator) => {
            if (this.props.templateConfig.country_code === 'LU') {
              return true; // Lux doesn't need any postal code, all are valid.
            }

            const urlParams = new URLSearchParams(window.location.search);
            if (urlParams.get('country') != 'France') {
              if (val !== '') {
                if (self.props.getContactFormInformation.cityOptions !== undefined) {
                  if (self.props.getContactFormInformation.cityOptions.length === 0) {
                    return false;
                  } else {
                    return true;
                  }
                } else {
                  return false;
                }
              }
            } else {
              return true;
            }
          },
          required: true,
        },
        localite: {
          message: this.props.t('The postal code entered is invalid.'),
          rule: (val, params, validator) => {
            if (this.props.templateConfig.country_code === 'LU') {
              return true; // Luxembourg doesn't need any postal code, alle are valid.
            }
            if (val === '' && (companyName !== COMPANY_TPBAT || companyName !== COMPANY_TPHOME)) {
              return false;
            }
          },
        },
        goal: {
          required: true,
          rule: (val, params, validator) => {
            return val !== null && val !== '';
          },
          message: this.props.t('Please enter your choice: to live in/to invest in.'),
        },
      },
      messages: {
        required: this.props.t('Please enter your :attribute.'),
        lastname: this.props.t('Please enter your Name.'),
        email: this.props.t('Please enter your email address.'),
        localite: this.props.t('The postal code entered is invalid.'),
      },
    });
  }

  /**
   * @return {void}
   */
  async componentDidMount() {
    this.initDynamics();
    this.props.createContactInformation(this.props);
    const dt = this.props.getProjectDetail.projectDetails === undefined ? this.props.project : this.props.getProjectDetail.projectDetails;
    this.setState({
      projectId: dt.projectId,
      projectName: dt.projectName,
      sociCode: dt.sociCode,
    });
  }

  /**
   * initiates the dynamics marketing form logic
   */
  initDynamics() {
    if (this.props.getMsDynamicsConfig && this.props.getMsDynamicsConfig.pdf_form_enable) {
      document.addEventListener('d365mkt-afterformload', () => {
        for (const form of document.forms) {
          if ( form.className === 'marketingForm') {
            this.preFillForm(form);
          }
        }
      });

      this.createDynamicFormScript();
    }
  }

  /**
   * Fills in hidden fields with the required information.
   * @param {HTMLFormElement} form
   */
  preFillForm(form) {
    if (form.elements['pdw_slt_label'] !== undefined) {
      form.elements['pdw_slt_label'].value = this.props.project.projectName;
    }

    if (form.elements['pdw_slt_code'] !== undefined) {
      form.elements['pdw_slt_code'].value = this.props.propertyModal.property.enseCode;
    }

    if (form.elements['pdw_slt_codelabel'] !== undefined) {
      form.elements['pdw_slt_codelabel'].value = this.props.propertyModal.property.projectPropertyCode;
    }

    if (form.elements['pdw_slt_lotlabel'] !== undefined) {
      form.elements['pdw_slt_lotlabel'].value = this.props.propertyModal.property.projectPropertyName;
    }

    if (form.elements['pdw_slt_codelot'] !== undefined) {
      form.elements['pdw_slt_codelot'].value = this.props.propertyModal.property.unitCode;
    }

    if (form.elements['pdw_foldingfile'] !== undefined && this.props.propertyModal.property.propertyPdfLinks && this.props.propertyModal.property.propertyPdfLinks.length > 0) {
      form.elements['pdw_foldingfile'].value = this.props.propertyModal.property.propertyPdfLinks[0];
    }

    if (form.elements[this.props.getMsDynamicsConfig.tp_sales_field_name] !== undefined) {
      let valueToSelect = '';

      switch (this.props.project.companyName) {
        case COMPANY_TPBAT:
          valueToSelect = this.props.getMsDynamicsConfig.tp_bat_value;
          break;
        case COMPANY_TPHOME:
          valueToSelect = this.props.getMsDynamicsConfig.tp_home_value;
          break;
        default:
          valueToSelect = this.props.getMsDynamicsConfig.tp_other_value;
          break;
      }

      form.elements['pdw_os_salesteam'].value = valueToSelect;
    }
  }


  /**
   * Inserts the dynamics form script.
   */
  createDynamicFormScript() {
    const script = document.createElement('script');
    script.src = `${this.props.getMsDynamicsConfig.pdf_form_script_src}?time${Date.now()}`;
    script.async = true;
    script.id = 'dynamicsForm';
    document.body.appendChild(script);
  }

  /**
   * Deletes the form script to be able to re-init it when modal is back opened.
   */
  removeDynamicForm() {
    const script = document.getElementById('dynamicsForm');
    if (script) {
      script.remove();
      window.d365mktforms = undefined;
      window.d365mkt = undefined;
    }
  }

  /**
   *
   * @return {Promise<void>}
   */
  async componentWillUnmount() {
    this.removeDynamicForm();
  }

  /**
   * @param {*} prevProps
   * @param {*} prevState
   */
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.showModal !==
        this.props.getContactFormInformation.showModal) {
      this.setState({
        showModal: this.props.getContactFormInformation.showModal,
      });
    }
    // when contactId changed contactform will load appointment data
    if (this.props.getContactFormInformation.contactId !==
      this.state.contactId &&
      this.props.getContactFormInformation.contactId !== undefined) {
      this.setState({
        contactId: this.props.getContactFormInformation.contactId,
        purpose: this.props.getContactFormInformation.purpose,
        projectName: this.props.getContactFormInformation.projectName,
        sociCode: this.props.getContactFormInformation.sociCode,
      });
    }
  }

  /**
   *
   * @return {void};
   */
  closeModal() {
    this.props.getContactFormInformation.showModal = false;
    this.props.getContactFormInformation.staticSelect = false;
    this.props.getContactFormInformation.postalCode = '';
    this.props.getContactFormInformation.idpostalCode = '';
    this.props.getContactFormInformation.cityOptions = [];
    this.props.createContactInformation(this.props.getContactFormInformation);
    this.resetForm('openForm');
    setTimeout(() => {
      // window.scrollTo(0, document.body.scrollHeight ||
      // document.documentElement.scrollHeight);
      document.documentElement.style.overflow = 'auto';
    }, 500);
    this.props.setModal();
  }

  /**
   *
   * @param {*} statusForm;
   */
  resetForm(statusForm) {
    this.setState({
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      street: '',
      streetNumber: '',
      contactphase: statusForm,
      city: '',
      cityId: '',
      postalcode: '',
      postalcodeId: '',
      message: '',
      termsCheckbox: false,
      cptCode: '',
      mailBox: '',
    });
    this.validator.hideMessages();
    this.forceUpdate();
    document.getElementById('popupProjectDetailContactForm').reset();
  }


  /**
   * @return {void}
   */
  createDataLayer() {
    // cerate data layer
    if (this.state.arrTrackedEntity.indexOf(this.state.project?.entity) !== -1) {
      const contactDataLayer = this.props.createContactDataLayer(this.state);
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM,
        dataLayer: contactDataLayer.payload,
      });
    }
  }
  /**
   *
   * @return {void};
   */
  async submitForm() {
    if (this.validator.allValid()) {
      const formOdoo = this.props.createContactData(this.state);
      this.createDataLayer();
      if (this.props.contactPdf) {
        formOdoo.payload.propertyData.pdfPlan = this.props.property.propertyPdfLinks[0];
        formOdoo.payload.purpose = 'PropertyDocumentation';
      } else {
        formOdoo.payload.purpose = 'ContactAboutProperty';
      }
      formOdoo.payload.propertyData.sociCode = parseInt(this.props.property.sociCode) ?? '-';
      formOdoo.payload.propertyData.propertyType = parseInt(this.props.property.projectPropertyType) ?? '-';
      formOdoo.payload.propertyData.projectName = this.props.project.projectName ?? '-';
      formOdoo.payload.propertyData.propertyName = this.props.property.projectPropertyName ?? '-';
      let contactphase = 'loading';
      this.setState({
        contactphase,
      });

      const urlNew = formOdoo.payload.propertyData.url.split( '?' );
      formOdoo.payload.propertyData.url = urlNew[0];
      let res = await submitContactForm(
          {
            data: formOdoo.payload,
            country: this.props.templateConfig.country,
          },
      );
      res = res.data;
      let image = 'tp-icons-newsletter-new-success.svg';
      let message = this.props.t('Your request has been sent');
      let messageSecond = this.props.t('We will contact you as soon as possible');

      contactphase = 'showResult';
      if (!res.success) {
        image = 'tp-icons-newsletter-new-fail.svg';
        message = this.props.t('Our website is experiencing a problem.');
        messageSecond = this.propts.t('An email will be sent to you to confirm your appointment');
        res.processResults.forEach(function(item) {
          if (item.process === 'CreateAppointment' && item.result === 'Error') {
            contactphase = 'openForm';
          }
        });
        this.validator.showMessages();
      } else {
        this.resetForm('loading');
      }
      await this.setState({
        submitResult: {
          image,
          message,
          messageSecond,
        },
        contactphase,
      });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @return {void};
   * @param {*} e
   */
  async changeOptions(e) {
    const input = e.target.value;
    this.props.getContactFormInformation.staticSelect = false;
    const minLength = 3;
    if (input.length > minLength) {
      this.props.getContactFormInformation.loadingOptions = true;
      this.props.createContactInformation(
          this.props.getContactFormInformation,
      );
      let resCities = await getCitiesForZipCode(
          {id: input},
      );
      //  reset form zip code
      this.setState({
        postalcode: this.props.templateConfig.country_code === 'LU' ? input : null,
        postalcodeId: null,
        city: null,
        cityId: null,
      });
      if (resCities.data.success) {
        resCities = resCities.data.hits;
        const formatZipcode = resCities.map(function(item) {
          return {
            value: item.id,
            label: item.name,
          };
        });
        if (resCities.length > 0) {
          this.props.getContactFormInformation.idpostalCode =
          resCities[0].zipCode.id;
          this.props.getContactFormInformation.staticSelect = true;
          this.props.getContactFormInformation.cityOptions = formatZipcode;
          this.props.getContactFormInformation.loadingOptions = false;
          this.setState({
            postalcode: input,
            postalcodeId: resCities[0].zipCode.id,
            city: formatZipcode[0].label,
            cityId: formatZipcode[0].value,
            cityValue: formatZipcode[0],
          });
        } else {
          this.props.getContactFormInformation.cityOptions = [];
          this.setState({
            postalcode: this.props.templateConfig.country_code === 'LU' ? input : null,
            postalcodeId: null,
            city: null,
            cityId: null,
            cityValue: {
              value: '',
              label: '',
            },
          });
        }
      }
    } else {
      this.props.getContactFormInformation.cityOptions = [];
      this.setState({
        postalcode: this.props.templateConfig.country_code === 'LU' ? input : null,
        postalcodeId: null,
        city: null,
        cityId: null,
        cityValue: {
          value: '',
          label: '',
        },
      });
    }
    this.props.createContactInformation(
        this.props.getContactFormInformation,
    );
  }

  // TODO: uncomment if need select on city selection
  // FIXME: if not using select anymore please delete
  /**
   *
   * @param {*} e
   */
  changeStaticOptions(e) {
    this.setState({
      city: e.label,
      cityId: e.value,
      cityValue: e,
    });
    this.validatePostalCode(e.value);
  }

  /**
   * @return {void};
   * @param {*} e
   */
  changePostalCode(e) {
    const self = this;
    const promise = new Promise( (resolve) => {
      clearTimeout(this.state.timeout);
      this.setState({
        timeout: setTimeout(() => {
          resolve(self.getPostalCode(e));
        }, 1000),
      });
    });
    return promise;
  }

  /**
   *
   * @param {*} e
   */
  setMailBox(e) {
    this.setState({
      mailbox: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  async getPostalCode(e) {
    const minLength = 3;
    if (e.length < minLength) {
      return;
    }
    if (this.state.postalcode!==null && this.state.postalcode===[]) {
      return;
    }
    const dt = await getPostalCodeApi(e, 'ALL', this.props.i18n.language);
    const formatZipcode = dt.data.hits.map(function(item) {
      return {
        value: item.id,
        label: item.name,
      };
    });

    if (formatZipcode.length > 0) {
      return formatZipcode;
    } else {
      return [];
    }
  }

  /**
  *
  * @param {*} e
  */
  async validatePostalCode(e) {
    if (!isNaN(parseInt(e))) {
      const dt = await getZipCodeByCity(e, this.props.i18n.language);
      if (dt.data.hits.length) {
        if (this.state.postalcode !== dt.data.hits[0].name) {
          this.setState({
            postalcode: dt.data.hits[0].name,
          });
        }
      }
    }
  }

  /**
   *
   * @param {*} e
   */
  setFirstname(e) {
    this.setState({
      firstname: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setLastname(e) {
    this.setState({
      lastname: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setPhone(e) {
    this.setState({
      defaultTel: e.target.value,
      phone: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setDefaultValuePhone(e) {
    if (e.target.value === '') {
      this.setState({
        defaultTel: this.state.phoneInfo.code,
        phone: this.state.phoneInfo.code,
      });
    }
  }

  /**
   *
   * @param {*} e
   */
  setStreet(e) {
    this.setState({
      street: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setTextMessage(e) {
    this.setState({
      message: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setStreetNumber(e) {
    this.setState({
      streetNumber: e.target.value,
    });
  }

  /**
   *
   * @param {*} e
   */
  setTermsCheckbox(e) {
    this.setState({
      termsCheckbox: e.target.checked,
    });
  }

  /**
   *
   * @param {*} e
   */
  setPostalCode(e) {
    this.setState({
      postalcode: e.target.value,
    });
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('country') !== COUNTRIES['FR']) {
      this.changeOptions(e);
    }
  }

  /**
   *
   * @param {*} e
   */
  setLocality(e) {
    this.setState({
      city: e.target.value,
    });
  }


  /**
   *
   * @param {*} pdflink
   */
  setPDFLink(pdflink) {
    this.setState({
      pdf: pdflink,
    });
  }

  /**
   *
   * @param {*} e
   */
  setGoal(e) {
    this.setState({
      goal: e.target.value === '0' ? false : true,
    });
  }

  /**
   *
   * @return {JSX.Element}
   */
  render() {
    if (this.props.getMsDynamicsConfig && this.props.getMsDynamicsConfig.pdf_form_enable && this.state.project?.companyName === COMPANY_TPHOME && this.props.contactPdf) {
      return (
        <div
          data-form-id={this.props.getMsDynamicsConfig.pdf_form_id}
          data-form-api-url={this.props.getMsDynamicsConfig.pdf_form_api_url}
          data-cached-form-url={this.props.getMsDynamicsConfig.pdf_form_cached_url}></div>
      );
    }

    let indexDynamicRequired = ''; // make it use for telp only
    let bottomRequired = '';
    let phoneValidationType = 'intlTelNumber';
    if (!this.props.contactPdf) {
      indexDynamicRequired = <span className="color-green-secondary">*</span>;
      phoneValidationType = 'required|intlTelNumber';
      bottomRequired = <span className="color-green-secondary">*</span>;
    }
    this.validator.purgeFields();
    const postalCode = this.props.getContactFormInformation.postalCode;
    let zipcodeSelect = '';
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('country') !== COUNTRIES['FR']) {
      if (this.props.getContactFormInformation.staticSelect) {
        if (this.props.templateConfig.country_code === 'LU') {
          zipcodeSelect = <Creatable
            type="text"
            id="selectLocality"
            name="your-locality"
            isClearable
            allowCreate={true}
            formatCreateLabel={(userInput) => `${userInput}`}
            allowCreateWhileLoading={true}
            options={this.props.getContactFormInformation.cityOptions}
            onChange={this.changeStaticOptions.bind(this)}
            value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
            noOptionsMessage={() => this.props.t('Enter your locality')}
            placeholder={this.props.t('Your locality')}
          />;
        } else {
          zipcodeSelect = <Select
            type="text"
            id="selectLocality"
            name="your-locality"
            options={this.props.getContactFormInformation.cityOptions}
            onChange={this.changeStaticOptions.bind(this)}
            value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
            noOptionsMessage={() => this.props.t('Enter your locality')}
            placeholder={this.props.t('Your locality')}
          />;
        }
      } else {
        if (this.props.templateConfig.country_code === 'LU') {
          zipcodeSelect = <AsyncCreatable
            type="text"
            id="selectLocality required"
            name="your-locality"
            isClearable
            allowCreate={true}
            formatCreateLabel={(userInput) => `${userInput}`}
            allowCreateWhileLoading={true}
            defaultOptions={this.props.getContactFormInformation.cityOptions}
            onChange={this.changeStaticOptions.bind(this)}
            loadOptions={this.changePostalCode.bind(this)}
            value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
            cacheOptions
            noOptionsMessage={() => this.props.t('Enter your locality')}
            placeholder={this.props.t('Your locality')}
          />;
        } else {
          zipcodeSelect = <AsyncSelect
            type="text"
            id="selectLocality required"
            name="your-locality"
            defaultOptions={this.props.getContactFormInformation.cityOptions}
            onChange={this.changeStaticOptions.bind(this)}
            loadOptions={this.changePostalCode.bind(this)}
            value={this.state.cityValue.value === '' ? this.state.cityValue.value : this.state.cityValue}
            cacheOptions
            noOptionsMessage={() => this.props.t('Enter your locality')}
            placeholder={this.props.t('Your locality')}
          />;
        }
      }
    } else {
      zipcodeSelect = <input type="text"
        className="form-control"
        placeholder={this.props.t('Your locality')}
        id="selectLocality"
        name="your-locality"
        value={this.state.city}
        onChange={this.setLocality.bind(this)}
      />;
    }

    let phaseFormClass = 'hide';
    let phaseLoadingClass = 'hide';
    let phaseResultClass = 'hide';
    switch (this.state.contactphase) {
      case 'openForm':
        phaseFormClass= '';
        break;
      case 'loading':
        phaseLoadingClass= '';
        break;
      case 'showResult':
        phaseResultClass= '';
        break;
      default:
        phaseFormClass= '';
        break;
    }
    const projectNameInput = `${this.props.project.projectName}-
      ${this.props.property.projectPropertyName}`;

    let textAreaInput = '';
    if (!this.props.contactPdf) {
      textAreaInput = <div id="messageField">
        <div className="form-container">
          <div className="form-group form-full">
            <label htmlFor="messageTextArea">{this.props.t('Message')}</label>
            <textarea name="message" id="messageTextArea" className="form-control" onChange={this.setTextMessage.bind(this)}></textarea>
          </div>
        </div>
      </div>;
    }

    //  if entity is TPBAT and contactpdf is true then localite and postal code no need to required
    const companyName = this.props.project.companyName;
    let validationTpBat = '';
    if (companyName === COMPANY_TPBAT) {
      validationTpBat= '';
      indexDynamicRequired = '';
      bottomRequired = '';
      phoneValidationType = 'intlTelNumber';
    }
    if (this.props.templateConfig.template == 'THOFR') {
      validationTpBat = '|required';
      bottomRequired = <span className="color-green-secondary">*</span>;
    }
    if (this.props.contactPdf) {
      validationTpBat= '';
      indexDynamicRequired = '';
      phoneValidationType = 'intlTelNumber';
      bottomRequired = '';
    }
    bottomRequired = '';
    const entity = this.props.project.entity.toLowerCase();

    return (
      <Fragment>
        <div className={'form-contact-body ' + phaseFormClass}>
          <div className="column-2 flex-100">
            <form id="popupProjectDetailContactForm"
              type="post"
            >
              <input type="hidden" id="subject_contact" name="subject"
                value="office_buy_house"
              />
              <input type="hidden"
                id="unit_code_input" name="unit_code_input"
                value={this.props.property.unitCode}
              />
              <input type="hidden"
                id="project_type" name="project_type"
                value="contact_about_property"
              />
              <input type="hidden"
                id="property_id_input" name="property_id_input"
                value={this.props.property.cptCode}
              />
              <input type="hidden"
                id="project_name_input" name="project_name_input"
                value={projectNameInput} />
              <input type="hidden"
                id="project_socicode_input"
                name="project_socicode_input"
                value={this.props.property.projectSociCode}
              />
              <div className="form-container">
                <div className="form-group">
                  <input type="hidden" name="office" value="39" />
                  <label htmlFor="firstNameInput_appointment">
                    {this.props.t('First name')}
                    <span className="color-green-secondary">*</span>
                  </label>
                  <input type="text"
                    name="firstname"
                    className="form-control"
                    placeholder={this.props.t('Your first name')}
                    id="firstNameInput_appointment"
                    value={this.state.firstname}
                    onChange={this.setFirstname.bind(this)}
                  />
                  {this.validator.message(
                      this.props.t('First name'), this.state.firstname,
                      'required|letterWithAccents',
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="lastNameInput_appointment">
                    {this.props.t('Last name')}
                    <span className="color-green-secondary">*</span>
                  </label>
                  <input type="text"
                    name="lastname"
                    className="form-control"
                    placeholder={this.props.t('Your last name')}
                    id="lastNameInput_appointment"
                    value={this.state.lastname}
                    onChange={this.setLastname.bind(this)}
                  />
                  {this.validator.message(
                      this.props.t('Last name'), this.state.lastname,
                      'required|letterWithAccents',
                  )}
                </div>
              </div>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="emailInput_appointment">
                    {this.props.t('E-mail')}
                    <span className="color-green-secondary">*</span>
                  </label>
                  <input type="email"
                    className="form-control"
                    placeholder={this.props.t('E-mail')}
                    id="emailInput_appointment"
                    name="email"
                    value={this.state.email}
                    onChange={this.setEmail.bind(this)}
                  />
                  {this.validator.message(
                      this.props.t('E-mail'), this.state.email,
                      'required|email',
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="telephoneInput">
                    {this.props.t('Phone')}
                    {indexDynamicRequired}
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    placeholder={this.props.t('Eg.: {{phoneFormat}}', {
                      phoneFormat: this.state.phoneInfo.format,
                    })}
                    id="telephoneInput"
                    name="phone"
                    onChange={this.setPhone.bind(this)}
                    onClick={this.setDefaultValuePhone.bind(this)}
                    value={this.state.phone}
                    defaultValue={this.state.defaultTel}
                  />
                  {this.validator.message(
                      this.props.t('Phone number'), this.state.phone,
                      phoneValidationType,
                  )}
                </div>
              </div>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="streetInput">
                    {this.props.t('Street name')}
                  </label>
                  <input type="text"
                    name="street"
                    className="form-control"
                    placeholder={this.props.t('Your street name')}
                    id="streetInput"
                    onChange={this.setStreet.bind(this)}
                  />
                </div>
                {entity === ENTITY_TPBAT.toLowerCase() && (
                  <div className="form-container divide">
                    <div className="form-group">
                      <label htmlFor="streetNumberInput">
                        {this.props.t('Street number')}
                      </label>
                      <input type="text"
                        name="street-number"
                        className="form-control"
                        placeholder={this.props.t('Your street number')}
                        id="streetNumberInput"
                        onChange={this.setStreetNumber.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="mailBox">
                        {this.props.t('Mailbox')}
                      </label>
                      <input type="text"
                        name="mail-box"
                        className="form-control"
                        placeholder={this.props.t('Your mailbox')}
                        id="mailBox"
                        onChange={this.setMailBox.bind(this)}
                      />
                    </div>
                  </div>
                )}
                {entity !== ENTITY_TPBAT.toLowerCase() && (
                  <div className="form-group">
                    <label htmlFor="streetNumberInput">
                      {this.props.t('Street number')}
                    </label>
                    <input type="text"
                      name="street-number"
                      className="form-control"
                      placeholder={this.props.t('Your street number')}
                      id="streetNumberInput"
                      onChange={this.setStreetNumber.bind(this)}
                    />
                  </div>
                )}
              </div>
              <div className="form-container">
                <div className="form-group">
                  <label htmlFor="yourPostalCode">
                    {this.props.t('Postal code')}
                    {bottomRequired}
                  </label>
                  <input type="text"
                    className="form-control"
                    placeholder={this.props.t('Your postal code')}
                    id="yourPostalCode"
                    name="your-postal-code"
                    defaultValue={postalCode}
                    value={this.state.postalcode}
                    onChange={this.setPostalCode.bind(this)}
                  />
                  {this.validator.message(
                      this.props.t('Postal code'), this.state.postalcode,
                      'zipNoResult'+validationTpBat,
                  )}
                </div>
                <div className="form-group">
                  <label htmlFor="selectLocality">
                    {this.props.t('Locality')}
                    {bottomRequired}
                  </label>
                  <div className="select-container
                  select-locality-container"
                  >
                    {zipcodeSelect}
                    {this.validator.message(
                        this.props.t('Locality'), this.state.cityId,
                        'localite'+validationTpBat,
                    )}
                  </div>
                </div>
              </div>
              {entity === ENTITY_TPBAT.toLowerCase() && (
                <div className='form-container'>
                  <div className="form-group">
                    <label htmlFor="appartmentGoal">
                      {this.props.t('I\'m looking for an apartment')}
                      <span className="color-green-secondary">*</span>
                    </label>
                    <div className="form-group-radio">
                      <input type="radio" checked={this.state.goal === false} name="appartmentGoal" id="radio1" onChange={this.setGoal.bind(this)} value="0" ></input>
                      <label htmlFor="radio1"><span>{this.props.t('to live in')}</span></label>
                      <input type="radio" checked={this.state.goal === true} name="appartmentGoal" id="radio2" onChange={this.setGoal.bind(this)} value="1" ></input>
                      <label htmlFor="radio2"><span>{this.props.t('to invest in')}</span></label>
                    </div>
                    {this.validator.message(this.props.t('to live in') + '/' + this.props.t('to invest in'), this.state.goal, 'goal')}
                  </div>
                </div>
              )}
              { textAreaInput }
              <div className="checkbox-group-container">
                <h4 className="purchase-policy-heading">
                  <span
                    dangerouslySetInnerHTML=
                      {{__html: this.props.t('Privacy policy (for more information see our {{policy}}):', {
                        policy: `<a href="https://www.thomas-piron.eu/politique-de-confidentialite" target="_blank">${this.props.t('Privacy policy')}</a>`,
                      })}}
                  /> :
                  <span className="color-green-secondary">*</span>
                </h4>
                <div className="form-container">
                  <div className="form-group inline
                  form-full form-group-checkbox">
                    <input className="form-control"
                      type="checkbox"
                      name="checkbox-privacy"
                      id="checkboxPrivacy"
                      onClick={this.setTermsCheckbox.bind(this)}
                    />
                    <label htmlFor="checkboxPrivacy">
                      <i>{this.props.t('I accept your privacy policy')}</i>
                    </label>
                  </div>
                  {this.validator.message(
                      'terms', this.state.termsCheckbox,
                      'gdpr',
                  )}
                  <i className="note">
                    {this.props.t('You can always modify your consent by contacting us at privacy@thomas-piron.eu.')}
                  </i>
                </div>
              </div>
              <div className="checkbox-group-container">
                <div className="form-group">
                  {this.props.t('* required field')}
                </div>
              </div>
              <div className="action-container full">
                <div className="captcha-container">
                </div>
                <div className="button-container">
                  <button type="button"
                    className="btn btn-xlarge btn-secondary"
                    onClick={this.submitForm.bind(this)}
                  >
                    {this.props.t('Send')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className={'loading-container ' + phaseLoadingClass}>
          <div className="loading">
            <i className="fas fa-spinner fa-spin"></i>
          </div>
          <div className="loading-text">
            <h5>{this.props.t('Loading')}</h5>
          </div>
        </div>
        <div className={'message-container ' + phaseResultClass}>
          <div className="image-message-container">
            <img id='imageMessage'
              src={
                process.env.REACT_APP_HOST +
                '/static/assets/images/icons/' +
                this.state.submitResult.image
              }
              alt=""
            />
          </div>
          <div className="text-message-container">
            <p id="textMessage">{this.state.submitResult.message}</p>
            <p id="textMessageSecondary">
              {this.state.submitResult.messageSecond}
            </p>
          </div>
          <div className="text-message-cta">
            <button
              className="btn btn-secondary
              btn-large modal-absolute-react-close-button"
              data-modal-close="modal-contact-form-react"
              onClick={this.closeModal.bind(this)}
            >
              {this.props.t('Close')}
            </button>
          </div>
        </div>
      </Fragment>
    );
  }
}

BasicContactForm.propTypes = {
  createContactInformation: PropTypes.func,
  showModal: PropTypes.bool,
  createLabelTranslate: PropTypes.func,
  closeModal: PropTypes.func,
  getContactFormInformation: PropTypes.object,
  contactInformation: PropTypes.object,
  getProjectDetail: PropTypes.object,
  property: PropTypes.object,
  project: PropTypes.object,
  createContactData: PropTypes.func,
  createContactDataLayer: PropTypes.func,
  setModal: PropTypes.func,
  setContactForm: PropTypes.bool,
  onOpenContactModal: PropTypes.func,
  contactPdf: PropTypes.bool,
  fromPropertyModal: PropTypes.bool,
  propertyModal: PropTypes.object,
  getContactDataLayer: PropTypes.object,
  templateConfig: PropTypes.object,
  getProjectPropertiesDetail: PropTypes.object,
  t: PropTypes.func,
  i18n: PropTypes.object,
  getMsDynamicsConfig: PropTypes.object,
};

const mapStateToProps = (state) =>{
  return state;
};

export default connect(mapStateToProps,
    {
      createContactInformation,
      createContactFormInformation,
      createContactData,
      createContactDataLayer,
    },
)(withTranslation()(BasicContactForm));
