import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import PropertyItemNew from '../Property/PropertyItemNew';
import * as ExternalApi from '../../../services/ExternalApi';
import {isMobileOnly} from 'react-device-detect';
import _ from '../../../helpers/lodash';
import SiteplanBreadcrumb from './SiteplanBreadcrumb';
import {
  TYPE_ESPACE_COMMERCIAL,
  TYPE_ESPACE_DE_BUREAU,
  TYPE_TERRAIN_A_BATIR,
  TYPE_MAISON,
  TYPE_APPARTEMENT, COMPANY_TPHOME, ENTITY_TPHOME,
} from '../../../helpers/constants';
import {withTranslation} from 'react-i18next';
// import queryString from 'query-string'; // in case residence is need to show, just un block these code below

/**
 * Class for detail page information.
 */
class ResultsTableNd extends React.Component {
  /**
   * @param {*} props
   * @return {void}
   */
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      trBody: '',
      classForFloor: '',
      classForRoom: '',
      hideFloor: false,
      hideRoom: false,
      projectProperties: [],
      propertiesDetail: {},
      finishSearch: false,
      paramsProperties: {},
      showTooltip: false,
      tableOrderBy: '',
      tableSort: true,
      reset: false,
      currentPage: 1,
      position: 'portrait',
      isLoading: false,
      firstLoad: true,
    };
    this.checkOrientation = this.checkOrientation.bind(this);
    this.tableRef = React.createRef(null);
    this.columnsShown = {};
    this.columnsShown[TYPE_MAISON] = ['js-balcony-garden', 'js-livable', 'js-number-of-rooms', 'js-description'], // 'js-terrain',
    this.columnsShown[TYPE_TERRAIN_A_BATIR] = ['js-balcony-garden', 'js-description'], // 'js-terrain',
    this.columnsShown[TYPE_APPARTEMENT] = ['js-livable', 'js-number-of-rooms', 'js-floor'], // 'js-balcony-garden'
    this.columnsShown[TYPE_ESPACE_COMMERCIAL] = ['js-livable', 'js-number-of-rooms', 'js-floor'], // 'js-balcony-garden'
    this.columnsShown[TYPE_ESPACE_DE_BUREAU] = ['js-livable', 'js-number-of-rooms', 'js-floor'], // 'js-balcony-garden'
    this.columnsToShow = ['js-action', 'js-full-price', 'js-price', 'js-project-property-name', 'js-project-phase']; // 'js-balcony-garden' default shown for every type.
  }

  /**
   * get floor image details
   * @param {Object} prevProps
   * @param {Object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevState.reset) {
      this.timer = setTimeout(() => this.setState({reset: false}), 1000);
    }
  }

  /**
   * add function rerender screen
   */
  componentDidMount() {
    const table = this.tableRef.current;
    if (this.tableRef !== undefined && table!==null) {
      window.addEventListener('resize', this.checkOrientation);
    };
  }

  /**
   * unmount component to clear interval or timeout
   */
  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    window.removeEventListener('resize', this.checkOrientation);
  }

  /**
   * @param {Object} props
   * @param {Object} state
   * @return {Object} state
   */
  static getDerivedStateFromProps(props, state) {
    if (state.paramsProperties !== props.params) {
      if (state.firstLoad) {
        state.firstLoad = false;
      } else {
        state.reset = true;
        state.finishSearch = false;
      }
    }

    state.currentPage= props.params.paging.currentPage;

    if (Object.keys(props.params).length > 0) {
      state.paramsProperties = props.params;
    }

    if (Object.keys(props.getProjectPropertiesDetail).length > 0) {
      state.projectProperties = _.cloneDeep(props.getProjectPropertiesDetail.projectProperties);
      state.propertiesDetail = _.cloneDeep(props.getProjectPropertiesDetail);
    }

    return state;
  }

  /**
   * @param {Number} page
   * @param {boolean} sortData
   * @return {void} */
  async loadMore(page, sortData) {
    if (this.state.isLoading) {
      return;
    }
    const params = this.state.paramsProperties;
    const projectProperties = [];
    params.paging.currentPage = 1;

    this.setState({
      paramsProperties: params,
      finishSearch: false,
      isLoading: true,
    });

    const response = await ExternalApi.getProjectProperties(params);
    if (response.data.projectProperties) {
      response.data.projectProperties.forEach((element) => {
        projectProperties.push(element);
      });
    }

    this.setState({
      projectProperties: projectProperties,
      isLoading: false,
    });
  }
  /**
   * @return {void}
   */
  openTooltip() {
    this.setState({showTooltip: true});
  }

  /**
   * @return {void}
   */
  closeTooltip() {
    this.setState({showTooltip: false});
  }

  /**
   * @param {*} tableOrderBy
   * @return {void}
   */
  onClickTableFilterHandler(tableOrderBy ) {
    const sortData = {
      column: tableOrderBy,
      order: !this.state.tableSort ? 'asc' : 'desc',
    };
    if (this.state.tableOrderBy === tableOrderBy) {
      this.setState({tableSort: !this.state.tableSort});
    }
    this.setState({tableOrderBy: tableOrderBy});
    const dtProperty = [];
    dtProperty.projectPropertyName = 'name';
    dtProperty.numberOfRooms = 'numberOfRoom';
    dtProperty.surfaceM2 = 'surface';
    dtProperty.surfaceOfBlaconyAndGardenM2 = 'BalconySurface';
    dtProperty.terrainM2 = 'terrain';
    dtProperty.price = 'price';
    dtProperty.priceAllIn = 'priceTTC';
    dtProperty.floor = 'floor';
    const params = this.state.paramsProperties;
    params.sortByPropertyColumn = dtProperty[sortData.column];
    params.sortByAscOrDesc = sortData.order;
    this.setState({paramsProperties: params});
    this.props.setFilterProjectDetail({
      id: this.props.project.projectId,
      sortedProps: {
        sortByPropertyColumn: params.sortByPropertyColumn,
        sortByAscOrDesc: params.sortByAscOrDesc,
      },
    });
    this.loadMore(null, true);
  }

  /**
   * @return {void}
   */
  checkOrientation() {
    if (isMobileOnly) {
      const windowWidth = window.innerWidth;
      if (windowWidth < 500) {
        this.setState({position: 'portrait'});
      } else {
        this.setState({position: 'landscape'});
      }
    }
  }

  /**
   * @param {string} str
   * @return {string}
   */
  removeAccents(str) {
    const chars = {
      'à': 'a',
      'â': 'a',
    };

    return str.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[àâ]/g, (m) => chars[m]);
  };

  /**
   * @param {string} str
   * @return {string}
   */
  removeSpaces(str) {
    return str.replaceAll(' ', '-');
  };

  /**
 *
 * @return {JSX.Element}
 */
  render() {
    // const searchParams = queryString.parse(window.location.search);
    // const listCountryWithoutPhase = ['france'];
    // const country = searchParams.country?.toLocaleLowerCase();
    // const withoutPhase = listCountryWithoutPhase.indexOf(country) > -1 ? true : false;
    const withoutPhase = true;
    const items = [];
    const itemsMobile = [];
    const checkCompanyName = COMPANY_TPHOME.toLowerCase();
    const entityType = (
        this.props.project.companyName !== undefined &&
        this.props.project.companyName) ? this.props.project.companyName.toLowerCase() : checkCompanyName;
    const isTPHome = checkCompanyName === entityType;
    const tableSort = this.state.tableSort ? 'asc' : 'desc';
    const sortedProjectProperties = _.orderBy(this.state.projectProperties, [this.state.tableOrderBy], [tableSort]);
    const tableClass = ['table', 'js-types'];

    sortedProjectProperties.map((property, i) => {
      const propertyTypeClass = 'table-' + this.removeSpaces(this.removeAccents(property.generalizedTypeOfProperty)).toLowerCase();
      this.columnsToShow = _.union(this.columnsToShow, this.columnsShown[property.projectPropertyType]);

      if (tableClass.indexOf(propertyTypeClass) === -1) {
        tableClass.push(propertyTypeClass);
      }

      items.push(
          <PropertyItemNew
            key={`${i}-desktop`}
            property={property}
            hideFloor = {this.state.hideFloor}
            hideRoom = {this.state.hideRoom}
            mediaBanner= {this.props.getMediaBannerDataAll}
            project={this.props.project}
            isTPHome={isTPHome}
            columns={this.columnsToShow}
            templateConfig={this.props.templateConfig}
          />,
      );
      itemsMobile.push(
          <PropertyItemNew
            key={`${i}-mobile`}
            property={property}
            hideFloor = {this.state.hideFloor}
            hideRoom = {this.state.hideRoom}
            mediaBanner= {this.props.getMediaBannerDataAll}
            project={this.props.project}
            columns={this.columnsToShow}
            templateConfig={this.props.templateConfig}
            mobile
          />,
      );
    });
    let principalPriceLabel = this.props.t('Main property price');
    if (this.props.project !== null && this.props.project.entity === ENTITY_TPHOME) {
      const countOfGeneralizedPropertyTypes = Object.keys(this.props.project.countOfGeneralizedPropertyTypes);
      if (countOfGeneralizedPropertyTypes.length === 1 && countOfGeneralizedPropertyTypes[0] === 'Maison') {
        principalPriceLabel = this.props.t('Price excluding fees and taxes');
      }
    }

    if (this.props.templateConfig.country_code !== 'BE') {
      principalPriceLabel = this.props.t('Property price');
    }

    return (
      <Fragment>
        {/* for desktop */}
        {
          !isMobileOnly && (<div className="project-detail-table-result-container-tpr" id="table-page-detail">
            <p className="result-table-desktop">{this.props.t('{{availableProperties}} properties available of {{count}}', {
              availableProperties: (this.state.propertiesDetail.avaibleProperties ? this.state.propertiesDetail.avaibleProperties : '0'),
              count: (this.state.propertiesDetail.count ? this.state.propertiesDetail.count : '0'),
            })}</p>
            <SiteplanBreadcrumb breadcrumb={this.props.dtBreadcrumb} triggerNavigation={(propertyCode)=> this.props.triggerNavigation(propertyCode)} position="result-table"/>
            <div className="project-detail-table-result-body">
              <table className={ tableClass.join(' ') }>
                <thead className="result-table-head">
                  <tr>
                    {withoutPhase ? ('') : (<th className={`${this.columnsToShow.includes('js-project-phase')? ' ' :'d-none'} residence-header`}>
                      <p>{(this.props.t('Residence'))}</p>
                    </th>)}
                    <th
                      className={`${isTPHome ? 'new-unit-header--bat': 'new-unit-header' }  ${this.columnsToShow.includes('js-project-property-name')? ' ' :'d-none'} ${this.state.tableOrderBy === 'projectPropertyName' ? `sorting--${tableSort}` : '' }`}
                      onClick={()=> this.onClickTableFilterHandler('projectPropertyName') }>
                      <p>{ isTPHome ? this.props.t('Unit') : this.props.t('Main unit') }</p>
                    </th>
                    <th className={`new-description-header ${this.columnsToShow.includes('js-description')? ' ' :'d-none'}`}>
                      <p>{this.props.t('Description')}</p>
                    </th>
                    <th
                      className={`new-room-header ${this.columnsToShow.includes('js-number-of-rooms')? ' ' :'d-none'} ${this.state.tableOrderBy === 'numberOfRooms' ? `sorting--${tableSort}` : '' }`}
                      onClick={ ()=> this.onClickTableFilterHandler('numberOfRooms') }>
                      <p>{this.props.t('Rooms')}</p>
                    </th>
                    <th
                      className={`floor-header ${this.columnsToShow.includes('js-floor')? ' ' :'d-none'} ${this.state.tableOrderBy === 'floor' ? `sorting--${tableSort}` : '' }`}
                      onClick={ ()=> this.onClickTableFilterHandler('floor') }>
                      <p>{this.props.t('Floor')}</p>
                    </th>
                    <th
                      className={`new-area-header ${this.columnsToShow.includes('js-livable')? ' ' :'d-none'} ${this.state.tableOrderBy === 'surfaceM2' ? `sorting--${tableSort}` : '' }`}
                      onClick={ ()=> this.onClickTableFilterHandler('surfaceM2') }>
                      <p>{this.props.t('Living space')}</p>
                    </th>
                    <th
                      className={`new-area-header ${this.columnsToShow.includes('js-balcony-garden')? ' ' :'d-none'} ${this.state.tableOrderBy === 'surfaceOfBlaconyAndGardenM2' ? `sorting--${tableSort}` : '' }`}
                      onClick={ ()=> this.onClickTableFilterHandler('surfaceOfBlaconyAndGardenM2') }>
                      <p>{this.props.t('Land surface')}</p>
                    </th>
                    <th
                      className={`new-area-header ${this.columnsToShow.includes('js-terrain')? ' ' :'d-none'} ${this.state.tableOrderBy === 'terrainM2' ? `sorting--${tableSort}` : '' }`}
                      onClick={ ()=> this.onClickTableFilterHandler('terrainM2') }>
                      <p>{this.props.t('Land surface')}</p>
                    </th>
                    <th
                      className={`main-price-header ${this.state.tableOrderBy === 'price' ? `sorting--${tableSort}` : '' }`}
                      onClick={ ()=> this.onClickTableFilterHandler('price')}>
                      <p>{principalPriceLabel} <span className="conditions">(1)</span></p>
                    </th>
                    {(this.props.templateConfig.country_code === 'BE') && (
                      <th
                        className={`full-price-header ${this.state.tableOrderBy === 'priceAllIn' ? `sorting--${tableSort}` : '' }`}
                        onClick={ ()=> this.onClickTableFilterHandler('priceAllIn')} >
                        <p>{this.props.t('Full price')} <span className="conditions">(2)</span></p>
                      </th>
                    )}
                    <th className="new-action-header"></th>
                  </tr>
                </thead>
                <tbody className="result-table-body" id="table-body" ref={this.tableRef}>
                  {(!this.state.reset && this.state.projectProperties && this.state.projectProperties.length > 0) ? (
                      <>{items}</>
                  ) : (
                    (!this.state.reset && this.state.projectProperties && this.state.projectProperties.length === 0) ? (
                      <><tr><td className="column-no-results"><p>{this.props.t('There are no results')}</p></td></tr></>
                    ) : (
                      <div key="loader-reset" className="loader-react">{this.props.t('Loading')}</div>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>)
        }

        {/* for mobile */}
        {
          isMobileOnly && (
            <>
              <p className="result-table-mobile">{this.state.propertiesDetail.avaibleProperties ? this.state.propertiesDetail.avaibleProperties : '0'} biens disponibles sur {this.state.propertiesDetail.count ? this.state.propertiesDetail.count : '0'}</p>
              <SiteplanBreadcrumb breadcrumb={this.props.dtBreadcrumb} triggerNavigation={(propertyCode)=> this.props.triggerNavigation(propertyCode)} position="result-table-mobile"/>
              <div className="project-detail-mobile-result">
                {(!this.state.reset && this.state.projectProperties && this.state.projectProperties.length > 0) ? (
                  <>
                    <ul className="project-detail-mobile-result-list">{itemsMobile}</ul>
                  </>
                ) : (
                    (!this.state.reset && this.state.projectProperties && this.state.projectProperties.length === 0) ? (
                        <p>{this.props.t('There are no results')}</p>
                    ) : (
                        <div key="loader-reset" className="loader-react">{this.props.t('Loading')}</div>
                    )
                )}
              </div>
            </>)
        }
      </Fragment>
    );
  }
}

ResultsTableNd.propTypes = {
  getProjectDetail: PropTypes.object,
  getProjectPropertiesDetail: PropTypes.object,
  getMediaBannerDataAll: PropTypes.any,
  project: PropTypes.object,
  params: PropTypes.object,
  contactInformation: PropTypes.object,
  setFilterProjectDetail: PropTypes.func,
  dtBreadcrumb: PropTypes.any,
  triggerNavigation: PropTypes.func,
  t: PropTypes.func,
  templateConfig: PropTypes.object,
};

export default withTranslation()(ResultsTableNd);
