import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

const DEFAULT_MAX_CHARACTERS = 500;

/**
 * Main Component
 * @return {JSX.Element} The rendered component.
 */
const PropertyDescription = ({description, maxCharacters = DEFAULT_MAX_CHARACTERS}) => {
  const {t} = useTranslation();
  const [isReadMore, setIsReadMore] = useState(false);

  const toggleReadMore = () => {
    setIsReadMore((prevState) => !prevState);
  };

  return (
    <div className={`property-description--${isReadMore ? 'more' : 'less'}`}>
      <div className='property-description__text inner-html' dangerouslySetInnerHTML={{__html: description}} />
      {description.length > maxCharacters && (
        <button className='property-description__button tp-btn--filled' onClick={() => toggleReadMore()}>
          {t(`Read ${isReadMore ? 'less' : 'more'}`)}
        </button>
      )}
    </div>
  );
};

PropertyDescription.propTypes = {
  description: PropTypes.string.required,
  maxCharacters: PropTypes.number,
};

PropertyDescription.defaultProps = {
  maxCharacters: DEFAULT_MAX_CHARACTERS,
};

export default PropertyDescription;
