import React, {useState, useEffect} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {BubleLoading} from '../helpers/loading';
import {COUNTRY_LANGUAGE_MAPPING, STAGAIRE_VACANCY_TYPE_IDS} from '../helpers/constants';
import * as JobApi from '../services/JobApi';
import JobCardWrapper from '../components/JobCard/JobCardWrapper';
import JobDetail from '../components/JobDetail/JobDetail';
import JobApplicationForm from '../components/JobDetail/JobApplicationForm';

/**
 * Page Job Category Element
 * @return {JSX.Element} The rendered component.
 */
const PageJobDetail = () => {
  const {t, i18n} = useTranslation();
  const [code, setCode] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [jobDetails, setJobDetails] = useState({});
  const [similarVacancies, setSimilarVacancies] = useState([]);
  const [initialApplicationValues, setInitialApplicationValues] = useState({});
  const [vacancyType, setVacancyType] = useState();
  const [jobsForVacancyType, setJobsForVacancyType] = useState([]);
  const [hasApplied, setHasApplied] = useState(false);
  const [isApprenticeship, setIsApprenticeship] = useState(false);
  const [country, setCountry] = useState('');

  const manipulateWebHtml = (job) => {
    // Change breadcrumb
    const breadCrumbs = document.querySelector('#block-thomaspiron-breadcrumbs ol')?.children;
    if (breadCrumbs) breadCrumbs[breadCrumbs.length - 1].innerHTML = job.title;

    // Change position to be filled
    const positionToBeFilled = document.querySelector('.job-name');
    if (positionToBeFilled) positionToBeFilled.innerHTML = job.title;
  };

  const loadInitialData = async () => {
    setIsLoading(true);

    const initialValues = await JobApi.getInitialApplicationValues();
    setInitialApplicationValues(initialValues);

    setIsLoading(false);
  };

  const fetchJobDetails = async () => {
    setIsLoading(true);

    const res = await JobApi.jobDetails(i18n.language, code);
    const job = res.vacancy;
    setJobDetails(job);
    setSimilarVacancies(res.similarVacancies);

    manipulateWebHtml(job);

    if (!initialApplicationValues) {
      console.error('Initial application values not loaded');
      setIsLoading(false);
      return;
    }

    const foundCountry = initialApplicationValues.jobCountries?.find((country) => country.countryCode === job.jobCountryCode);
    if (foundCountry) setCountry(foundCountry[COUNTRY_LANGUAGE_MAPPING[i18n.language] ?? COUNTRY_LANGUAGE_MAPPING['fr']]);

    const foundVacancyType = initialApplicationValues.typeVacancy?.find((type) => type.vacancyTypeFR === res.type);
    if (foundVacancyType) setVacancyType(foundVacancyType);

    setIsLoading(false);
  };

  const fetchJobsForVacancyType = async () => {
    setIsLoading(true);

    const res = await JobApi.searchJobs(i18n.language, {
      vacancyTypeIds: vacancyType?.id ? [vacancyType.id] : [],
    });

    setJobsForVacancyType(res.jobs);

    setIsLoading(false);
  };

  // Initial logic. Get job code from URL
  useEffect(() => {
    loadInitialData().then(() => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const jobCode = urlParams.get('code');

      if (jobCode) setCode(jobCode ?? '');
    });
  }, []);

  // Fetch job detail
  useEffect(() => {
    if (!code || code === '') {
      setVacancyType(null);
      return;
    };

    fetchJobDetails();
  }, [code]);

  // Fetch jobs for vacancy type
  useEffect(() => {
    fetchJobsForVacancyType();

    if (STAGAIRE_VACANCY_TYPE_IDS.includes(vacancyType?.id)) setIsApprenticeship(true);
    else setIsApprenticeship(false);
  }, [vacancyType]);

  useEffect(() => {
    if (isLoading === false && window.location.hash === '#postuler') {
      scrollToApply();
    }
  }, [isLoading]);

  useEffect(() => {
    if (!jobDetails || !initialApplicationValues) return;
  }, [jobDetails, initialApplicationValues]);

  const scrollToApply= () => {
    document.getElementById('postuler').scrollIntoView();
  };

  const formatJobApplicationFormData = (values) => {
    const formData = new FormData();
    formData.append('Lang', i18n.language);

    const appendFormData = (data, parentKey = '') => {
      Object.keys(data).forEach((key) => {
        const fullKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof data[key] === 'object' && data[key] !== null && !Array.isArray(data[key]) && !(data[key] instanceof File) && !(key.startsWith('Files'))) {
          appendFormData(data[key], fullKey);
        } else {
          formData.append(fullKey, data[key]);
        }
      });
    };

    appendFormData(values);
    return formData;
  };

  const handleSendForm = async (values) => {
    const formData = formatJobApplicationFormData(values);
    const res = await JobApi.sendApplication(formData);
    scrollToApply();

    if (res.status !== 200) {
      throw new Error('Error sending application');
    };

    setHasApplied(true);
  };

  return (
    !isLoading ? (
      <div>
        {code ? (
          <>
            <JobDetail job={jobDetails} countries={initialApplicationValues.jobCountries ?? []} />
            <div className='job-detail__spacer' aria-hidden />
          </>
        ) : null}
        <section>
          <h2 id='postuler'>{t('Registration form')}</h2>
          {hasApplied ? <p className='job-detail__applied'>{t('Your application has been sent!')}</p> : (
            <JobApplicationForm sendForm={handleSendForm} initialApplicationValues={initialApplicationValues} vacancyCode={code} jobsForVacancyType={jobsForVacancyType} isApprenticeship={isApprenticeship} />
          )}
        </section>
        {code ? (
          <>
            <div className='job-detail__spacer' aria-hidden />
            <JobCardWrapper
              heading={
                <span className='job-card-wrapper__head--bold'>
                  <Trans
                    i18nKey="Similar offers in {{country}}"
                    values={{country: country}}
                  />
                </span>
              }
              jobs={similarVacancies}
              countries={initialApplicationValues.jobCountries ?? []}
            />
          </>
        ) : null}
      </div>
    ) : (
      <div className="preloader">
        <BubleLoading key={Math.random()} text={t('Loading')} />
      </div>
    )
  );
};

export default PageJobDetail;
